<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="姓名">
          <el-input style="width: 180px" clearable v-model.trim="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input style="width: 180px" clearable v-model.trim="searchData.phone" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="城市">
          <el-input style="width: 180px" clearable v-model.trim="searchData.city" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            style="width: 210px"
            v-model="searchData.timeSlot"
            size="mini"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list">
      <el-table-column prop="name" label="姓名" min-width="120"> </el-table-column>
      <el-table-column prop="phone" label="手机号" min-width="150"> </el-table-column>
      <el-table-column prop="city" label="城市" min-width="120"> </el-table-column>
      <el-table-column prop="address" label="地址" min-width="150"> </el-table-column>
      <el-table-column prop="created_at" label="申请时间" min-width="150"> </el-table-column>
      <el-table-column prop="id" label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
  </div>
</template>

<script>
import { getListAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],

  data() {
    return {
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        city: '',
        name: '',
        timeSlot: [],
        phone: ''
      },
      total: 0
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { page, page_size, name, city, timeSlot, phone } = this.searchData
      let params = { page, page_size, name, city, phone }
      if (timeSlot && timeSlot.length > 0) {
        params.create_start_time = timeSlot[0]
        params.create_end_time = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleDel({ id }) {
      this.$confirm('确认删除该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
